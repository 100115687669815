import React from "react";
import NavSec from "./NavSec";
import Reveal from "react-reveal/Reveal";
import AboutInfo from "./AboutInfo";
import Sidebar from "../Sidebar/Sidebar";

export default function HomeMain() {
  return (
    <>
      <Sidebar />
      <Reveal effect="animNav">
        <NavSec />
      </Reveal>
      <AboutInfo />
    </>
  );
}
