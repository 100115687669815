import React from "react";
import NavSec from "../Home/NavSec";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import PageInfo from "./PageInfo";
import Footer from '../Footer/Footer'
import SetupMain from "./SetupMain";
import Sidebar from "../Sidebar/Sidebar";

export default function Setup() {
  return (
    <>
    <Sidebar />
      <Reveal effect="animNav">
        <NavSec />
      </Reveal>
      <Slide right>
        <PageInfo />
      </Slide>
        <SetupMain />
      <Footer />
    </>
  );
}
