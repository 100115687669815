import React from "react";
import { Navbar} from "reactstrap";
import { Link } from "react-router-dom";
import HamburgerButton from "../Hamburger Button/HamburgerButton";

const NavSec = () => {

    return (
        <>
            <Navbar>
                <HamburgerButton />
                <Link to="/" className="navbar-brand">
                    <b>Musobek</b>Madrimov
                </Link>
            </Navbar>
        </>
    )
}

export default NavSec;