import React from "react";

export default function PageInfo() {
  return (
    <div className="titles">
      <h3 className="title1">About Me</h3>
      <p className="title2">A little about me...</p>
    </div>
  );
}
