import React from "react";
import HomeMain from "./Home/HomeMain";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Styles/animation.scss";
import "./Styles/main.scss";
import './Styles/responsive.scss'
import Portfolio from "./Portfolio/Portfolio";
import Setup from "./Setup/Setup";
import About from "./About/About";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
