import React from "react";

export default function PageInfo() {
  return (
    <div className="titles">
      <h3 className="title1">Setup</h3>
      <p className="title2">About my setup</p>
    </div>
  );
}
