import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <Link to="/">
        &copy; Musobek Madrimov 2021 - {new Date().getFullYear()} | made with{" "}
        <span className="heartIcon">❤️</span>{" "}
      </Link>
    </div>
  );
}
