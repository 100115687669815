import React from "react";
import MyLaptopImage from "./images/VivoBook.jpg";
import { Container, Row, Col } from "reactstrap";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Slide from "react-reveal/Slide";

export default function SetupMain() {
  const myLaptop = {
    name: "Laptop ASUS Vivobook Pro 14 OLED M3401QC-KM043T (90NB0VF2-M00610) Quiet Blue",
    screen: {
      diagonal: '14"',
      type: "OLED",
      ghz: "90 GHz",
    },
    cpu: {
      cpu: "AMD Ryzen 7 5800H Octa Core (3.2 - 4.4 GHz)",
      os: "Windows 11",
    },
    ram: {
      ram: "16 GB",
      type: "DDR4",
    },
    storage: "512 GB",
    gpu: {
      gpu: "4 GB",
      type: "NVIDIA GeForce RTX 3050",
    },
    frame: {
      color: "Blue",
      weight: "1.4 Kg",
      sound: "Harman/Kardon",
      battery: "63 W/h",
      dimensions: "31.74 x 22.85 x 1.93 sm",
      type: "Metal + plastic",
    },
    additionally: [
      "Fingerprint identification, ",
      "Backlit keyboard, ",
      "Webcam",
    ],
  };

  return (
    <div className="setupMain">
      <Slide left>
        <div className="setupImage">
          <img src={MyLaptopImage} alt={myLaptop.name} />
        </div>
      </Slide>

      <Container>
        <Row>
          <Col md="8">
            <div className="mySetup">
              <span className="laptopSpecs">
                <ul>
                  <Slide top>
                    <a
                      href="https://www.asus.com/ru/Laptops/For-Home/Vivobook/Vivobook-Pro-14-OLED-M3401-AMD-Ryzen-5000-Series/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h2>
                        ASUS Vivobook Pro 14 <ArrowUpward />
                      </h2>
                    </a>
                  </Slide>

                  <Slide top>
                    <p>Screen:</p>
                    <li>
                      <b>Screen diagonal:</b> {myLaptop.screen.diagonal}
                    </li>
                    <li>
                      <b>Screen type:</b> {myLaptop.screen.type}
                    </li>
                    <li>
                      <b>Screen refresh rate:</b> {myLaptop.screen.ghz}
                    </li>
                  </Slide>
                  <Slide top>
                    <p>CPU:</p>
                    <li>
                      <b>CPU:</b> {myLaptop.cpu.cpu}
                    </li>
                    <li>
                      <b>OS:</b> {myLaptop.cpu.os}
                    </li>
                  </Slide>
                  <Slide top>
                    <p>RAM:</p>
                    <li>
                      <b>RAM:</b> {myLaptop.ram.ram}
                    </li>
                    <li>
                      <b>RAM type:</b> {myLaptop.ram.type}
                    </li>
                  </Slide>
                  <Slide top>
                    <p>Storage:</p>
                    <li>
                      <b>Storage:</b> {myLaptop.storage}
                    </li>
                  </Slide>
                  <Slide top>
                    <p>GPU:</p>
                    <li>
                      <b>GPU:</b> {myLaptop.gpu.gpu}
                    </li>
                    <li>
                      <b>GPU type:</b> {myLaptop.gpu.type}
                    </li>
                  </Slide>
                  <Slide top>
                    <p>Frame:</p>
                    <li>
                      <b>Color:</b> {myLaptop.frame.color}
                    </li>
                    <li>
                      <b>Weight:</b> {myLaptop.frame.weight}
                    </li>
                    <li>
                      <b>Sound system:</b> {myLaptop.frame.sound}
                    </li>
                    <li>
                      <b>Battery capacity:</b> {myLaptop.frame.battery}
                    </li>
                    <li>
                      <b>Dimensions:</b> {myLaptop.frame.dimensions}
                    </li>
                    <li>
                      <b>Material:</b> {myLaptop.frame.type}
                    </li>
                    <li>
                      <b>Additionally:</b>{" "}
                      {myLaptop.additionally.map((element) => element)}
                    </li>
                  </Slide>
                </ul>
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
