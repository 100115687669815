import React from "react";
import NavSec from "../Home/NavSec";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import PageInfo from "./PageInfo";
import Project from "./Project";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";

export default function Portfolio() {
  return (
    <>
    <Sidebar />
      <Reveal effect="animNav">
        <NavSec />
      </Reveal>
      <Slide left>
        <PageInfo />
      </Slide>
      <Project />
      <Footer />
    </>
  );
}
