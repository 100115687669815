import React, { useState, useEffect, useRef } from "react";

export default function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const [value, setValue] = useState();
  const prevValue = useRef("");

  useEffect(() => {
    prevValue.current = value;
  }, [value]);

  return (
    <ul className="pagination">
      {pageNumbers.map((number) => {
        return (
          <li
            key={number}
            className={
              number === currentPage ? "page-item active" : "page-item"
            }
          >
            <button
              className="page-link"
              onClick={(e) => {
                paginate(number);
                setValue(e.target);
              }}
            >
              {number}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
