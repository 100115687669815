import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./sidebar.scss";


export default function Sidebar() {
  useEffect(() => {
    document.getElementById("closeIcon").addEventListener("click", () => {
      document.querySelector(".hamburger").classList.remove("active");
      document.querySelector(".sidebar-menu").classList.remove("active");
    });
  });

  return (
    <div className="sidebar-menu">
      <div className="logo">
        <NavLink to="/" className="navbar-brand">
          <b>Musobek</b>Madrimov
        </NavLink>
        <i id="closeIcon">
          <HighlightOffIcon />
        </i>
      </div>
      <ul className="menu">
        <li>
          <NavLink activeclassname="activeLiElement" to="/">
            HOME
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="activeLiElement" to="/portfolio">
            PORTFOLIO
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="activeLiElement" to="/setup">
            SETUP
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="activeLiElement" to="/about">
            ABOUT
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
