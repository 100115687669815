import React from "react";
import AboutMeImage from "./images/9х16.jpg";
import Slide from "react-reveal/Slide";

export default function Main() {
  return (
    <div className="aboutMeMain">
      <Slide left>
        <img src={AboutMeImage} alt="MyImage" className="myImage" />
      </Slide>
      <Slide right>
        <div className="aboutMeText">
          <p>
            Hello, I am Musobek Madrimov! I am {new Date().getFullYear() - 1999}{" "}
            years old! I am from Uzbekistan, Khorezm region! I am currently
            living and working in Kyiv, Ukraine! I have started learning web
            developmnent since 2020 at the company{" "}
            <a href="http://a1tech.uz" target="_blank" rel="noreferrer">
              A1TechGroup
            </a>{" "}
            in Urgench!
          </p>
          <div className="stack">
            <b>My Stack:</b>
            <p>
              - HTML;
              <br /> - CSS;
              <br /> - Bootstrap;
              <br /> - JavaScript ❤️;
              <br /> - React;
              <br /> - PHP;
              <br /> - MySQL;
            </p>
          </div>
        </div>
      </Slide>
    </div>
  );
}
