import React from "react";
import myImage from "./images/maidan-nezalejnosti.jpg";
import { Link } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import GitHubIcon from "@mui/icons-material/GitHub";
import InfoIcon from "@mui/icons-material/Info";
import Reveal from "react-reveal";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import Typewriter from "typewriter-effect";

export default function AboutInfo() {
  return (
    <div className="aboutInfo">
      <div className="side2">
        <Roll right>
          <img
            src={myImage}
            alt="My Selfie in Maidan Nezalejnosti"
            className="homeImage"
          />
        </Roll>
      </div>
      <div className="side1">
        <Reveal effect="fadeInUp">
          <h1 className="title">
            <Typewriter
              options={{
                strings: [
                  "Assalamu alaykum, I'm Musobek!",
                  "I'm Frontend Developer from Uzbekistan!"
                ],
                autoStart: true,
                loop: true
              }}
            />
          </h1>
          <p className="description">
            22 y.o Frontend Developer from Uzbekistan! Welcome to my portfolio
            website!
          </p>
        </Reveal>
        <div className="buttons">
          <Fade left>
            <Link className="aboutBtn" to="/about">
              About Me
              <InfoIcon className="buttonIcon" />
            </Link>
          </Fade>
          <Fade right>
            <Link className="portfolioBtn" to="/portfolio">
              Portfolio
              <span className="indicator">
                <span className="arrowToRight"></span>
                <span className="arrowToRight"></span>
                <span className="arrowToRight"></span>
              </span>
            </Link>
          </Fade>
        </div>
        <Fade up>
          <ul className="socialNet">
            <li>
              <a
                className="links"
                href="https://www.youtube.com/channel/UCQ9FGGtND9zSapAr_AQVL_g"
                target="_blank"
                rel="noreferrer"
              >
                {<YouTubeIcon className="iconSvg" />}
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.instagram.com/musobekmadrimov"
                target="_blank"
                rel="noreferrer"
              >
                {<InstagramIcon className="iconSvg" />}
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.t.me/musobekmadrimov"
                target="_blank"
                rel="noreferrer"
              >
                {<TelegramIcon className="iconSvg" />}
              </a>
            </li>
            <li>
              <a
                className="links"
                href="https://www.github.com/musobekmadrimov"
                target="_blank"
                rel="noreferrer"
              >
                {<GitHubIcon className="iconSvg" />}
              </a>
            </li>
          </ul>
        </Fade>
      </div>
    </div>
  );
}
