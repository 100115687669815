import React from "react";

export default function PageInfo() {
  return (
    <div className="titles">
      <h3 className="title1">Portfolio</h3>
      <p className="title2">Check my portfolio</p>
    </div>
  );
}
