import React from "react";
import NavSec from "../Home/NavSec";
import Reveal from "react-reveal/Reveal";
import Slide from "react-reveal/Slide";
import Footer from "../Footer/Footer";
import PageInfo from "./PageInfo";
import Main from './Main'
import Sidebar from "../Sidebar/Sidebar";

export default function About() {
  return (
    <>
    <Sidebar />
      <Reveal effect="animNav">
        <NavSec />
      </Reveal>
      <Slide down>
        <PageInfo />
      </Slide>
      <Main />
      <Footer />
    </>
  );
}
