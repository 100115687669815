import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import LinkIcon from "@mui/icons-material/Link";
import SourceIcon from "@mui/icons-material/Source";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ElektronTasbeh from "./images/elektron-tasbeh.png";
import RockPaperScissors from "./images/dom-dom-ziki.png";
import VanillaJSClock from "./images/vanilla-js-clock.png";
import MemoryGame from "./images/memory-game.png";
import TicTacToe from "./images/tic-tac-toe.png";
import TodoApp from "./images/todo-app.png";
import Timer from "./images/timer.png";
import UssdUz from "./images/usduzuz.png";
import WeatherApp from "./images/weather-app.png";
import CurrencyTracker from "./images/crypto-tracker.png";
import VoiceAssistance from "./images/voice-assistance.png";
import ImageSearch from "./images/image-search.png";
import PizzaApp from "./images/pizza-app.png";
import QRCodeGenerator from "./images/qr-code-generator.png";
import eCommerce from "./images/e-commerce.png";
import Slide from "react-reveal/Slide";
import Pagination from "./Pagination";

export default function Project() {
  const [upButtonIsVisible, setupButtonIsVisible] = useState(false);
  const [posts, setPosts] = useState([]);
  const [currentPage, SetCurrentPage] = useState(1);
  const postsPerPage = 5;
  const projects = [
    {
      projectName: "e-Commerce Store",
      projectDesc: "e-Commerce Store using commerceJS, Stripe Payment API and ReactJS",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: eCommerce,
      projectLink: "https://musobeks-e-commerce-store.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/e-commerce",
    },
    {
      projectName: "QR Code Generator App",
      projectDesc: "QR Code Generator App using ReactJS",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: QRCodeGenerator,
      projectLink: "https://musobeks-qr-code-generator.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/qr-code-generator-app",
    },
    {
      projectName: "A simple Pizza Website",
      projectDesc: "A simple Pizza Website using ReactJS",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: PizzaApp,
      projectLink: "https://pizza-project-musobek.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/pizza-react",
    },
    {
      projectName: "Voice Assistance App with Alan AI",
      projectDesc: "Voice Assistance App using ReactJS and Alan AI",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: VoiceAssistance,
      projectLink: "https://musobeks-voice-assistance-app.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/Voice-Assistance-App",
    },
    {
      projectName: "Image Search App with UnSplash API",
      projectDesc: "A Simple Image App using ReactJS and UnSplash API",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: ImageSearch,
      projectLink: "https://musobeks-image-search-app.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/Image-Search-App",
    },
    {
      projectName: "Crypto Currency Tracker App",
      projectDesc:
        "Crypto Currency Tracker App using ReactJS and CoinGecko API",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: CurrencyTracker,
      projectLink: "https://musobeks-crypto-currency-tracker-app.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/e-currency-tracker",
    },
    {
      projectName: "Weather App",
      projectDesc: "Weather App using ReactJS and Weather API",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: WeatherApp,
      projectLink: "https://musobeks-weather-app.netlify.app",
      gitHubLink: "https://github.com/musobekmadrimov/React-Weather-App",
    },
    {
      projectName: "Simple Stop Watch",
      projectDesc: "A simple Stop Watch app using ReactJS",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: Timer,
      projectLink: "https://musobeks-timer.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/react-timer",
    },
    {
      projectName: "Simple Todo App",
      projectDesc: "A simple To-Do app using ReactJS",
      testLogin: "Not Required",
      testPassword: "Not Required",
      projectImage: TodoApp,
      projectLink: "https://musobeks-todo-app.netlify.app",
      gitHubLink: "https://github.com/musobekmadrimov/react-todo-app",
    },
    {
      projectName: "A1 USSD",
      projectDesc:
        "A dashboard for mobile companies for showing tarif plans to clients with online chat using HTML, CSS, Bootstrap, JavaScript, PHP and MySQL",
      testLogin: "musobekmadrimov",
      testPassword: "123",
      projectImage: UssdUz,
      projectLink: "https://usduz.uz/interfeys",
      gitHubLink: "",
    },
    {
      projectName: "Tic Tac Toe Game",
      projectDesc: "A simple tic-tac-toe game in HTML, CSS and Pure JavaScript",
      testLogin: "Not required",
      testPassword: "Not required",
      projectImage: TicTacToe,
      projectLink: "https://musobeks-tic-tac-toe-game.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/react-timer",
    },
    {
      projectName: "The Memory Game",
      projectDesc: "A simple memory game in HTML, CSS and Pure JavaScript",
      testLogin: "Not required",
      testPassword: "Not required",
      projectImage: MemoryGame,
      projectLink: "https://musobeks-memory-game.netlify.app//",
      gitHubLink: "https://github.com/musobekmadrimov/memory-game",
    },
    {
      projectName: "Vanilla JavaScript Clock",
      projectDesc: "A simple clock in HTML, CSS and Pure JavaScript",
      testLogin: "Not required",
      testPassword: "Not required",
      projectImage: VanillaJSClock,
      projectLink: "https://musobek-js-clock.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/Image-Search-App",
    },
    {
      projectName: "Rock–paper–scissors game",
      projectDesc:
        "A simple Rock–paper–scissors game game in HTML, CSS and Pure JavaScript",
      testLogin: "Not required",
      testPassword: "Not required",
      projectImage: RockPaperScissors,
      projectLink: "https://musobek-dom-dom-ziki.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/rock-paper-scissors",
    },
    {
      projectName: "Electron Tasbeh",
      projectDesc: "A simple electron tasbeh in HTML, CSS and Pure JavaScript",
      testLogin: "Not required",
      testPassword: "Not required",
      projectImage: ElektronTasbeh,
      projectLink: "https://elektron-tasbeh.netlify.app/",
      gitHubLink: "https://github.com/musobekmadrimov/Elektron-Tasbeh",
    },
  ];

  useEffect(() => {
    setPosts(projects);
  }, []);

  // Get Current Posts

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPost = posts.slice(indexOfFirstPost, indexOfLastPost);

  // CHange page
  function paginate(pageNumber) {
    SetCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function toggleVisibility() {
    if (
      document.body.scrollTop > 200 ||
      document.documentElement.scrollTop > 200
    ) {
      setupButtonIsVisible(true);
    } else {
      setupButtonIsVisible(false);
    }
  }

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  });

  return (
    <>
      <Container fluid="sm" style={{ maxWidth: "95%" }}>
        <Row style={{ paddingBottom: "65px" }}>
          <button
            className={upButtonIsVisible ? "show plus" : "plus"}
            id="upButton"
            onClick={scrollToTop}
          >
            <ArrowUpwardIcon />
          </button>

          {currentPost.map((project, index) => {
            return index % 2 === 0 ? (
              <Slide right key={index}>
                <Col md="12">
                  <div className="projectCard">
                    <img
                      src={project.projectImage}
                      alt={project.projectName}
                      className="projectImage"
                    />
                    <div className="aboutProject">
                      <div className="projectDescription">
                        <h3 className="projectName">{project.projectName}</h3>
                        <p className="projectParagraph">
                          {project.projectDesc}
                        </p>
                        <p className="testLogin">
                          Test Login for use Project: {project.testLogin}
                        </p>
                        <p className="testPassword">
                          Test Password for use Project: {project.testPassword}
                        </p>
                      </div>
                      <div className="projectButtons">
                        <a
                          href={project.projectLink}
                          target="_blank"
                          rel="noreferrer"
                          className="liveButton"
                        >
                          Live <LinkIcon />
                        </a>
                        <a
                          href={project.gitHubLink}
                          target="_blank"
                          rel="noreferrer"
                          className="demoButton"
                        >
                          Source <SourceIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <hr />
              </Slide>
            ) : (
              <Slide left key={index}>
                <Col md="12">
                  <div className="projectCard">
                    <img
                      src={project.projectImage}
                      alt={project.projectName}
                      className="projectImage"
                    />
                    <div className="aboutProject">
                      <div className="projectDescription">
                        <h3 className="projectName">{project.projectName}</h3>
                        <p className="projectParagraph">
                          {project.projectDesc}
                        </p>
                        <p className="testLogin">
                          Test Login for use Project: {project.testLogin}
                        </p>
                        <p className="testPassword">
                          Test Password for use Project: {project.testPassword}
                        </p>
                      </div>
                      <div className="projectButtons">
                        <a
                          href={project.projectLink}
                          target="_blank"
                          rel="noreferrer"
                          className="liveButton"
                        >
                          Live <LinkIcon />
                        </a>
                        <a
                          href={project.gitHubLink}
                          target="_blank"
                          rel="noreferrer"
                          className="demoButton"
                        >
                          Source <SourceIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <hr />
              </Slide>
            );
          })}
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={posts.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Row>
      </Container>
    </>
  );
}
